/* Vendor files ------------------------------------ */

$('h1, h2, h3, h4, h5, p').each(function () {
	var tekst = $(this).html();
	tekst = tekst.replace(/(\s)([\S])[\s]+/g, '$1$2&nbsp;'); //jednoznakowe
	tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, '$1$2&nbsp;'); //dwuznakowe
	$(this).html(tekst);
});

jQuery(function() {
	function setAspectRatio() {
		jQuery('iframe').each(function() {
			jQuery(this).css('height', jQuery(this).width() * 9/16);
		});
	}

	setAspectRatio();   
	jQuery(window).resize(setAspectRatio);
});